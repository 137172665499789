@import 'common-flagship_tasting_room';
@import "tasting-reservation";
@import 'common-wineclub';

//stylelint-disable
.pswp__img {
	background: $color-product_image-background;
}

.b-dialog-btn.m-alt {
	border: none;

	@media (hover: hover) and (pointer: fine) {
		&:hover {
			background: var(--button_alt-background_color, $color-button_alt-background);
			color: var(--button_alt-text_color, $color-button_alt-text);
		}
	}
}

.b-dialog-btn.m-alt .b-dialog-btn_text {
	@include t-link_underlined($state: default, $underline-offset: 5px, $font-weight: inherit);
	@include t-link_underlined($state: hover);

	letter-spacing: inherit;
}


.tier-tiles {
	font-family: $font-alt;
	.tierTitle {
		font: 500 26px $font-alt;
		letter-spacing: 0.5px;
		line-height: 40px;
		color:$color-secondary-accent;
	}
	.tier-descMemship {
		font-family: $font-main;
	}
}

.tier-list {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	@include media(md-down){
		align-self: flex-start;
	}

	@include media(sm) {
		display: block;
		padding: 15px;
	}
	.tier-list.b-carousel {
		align-items: flex-start;
		overflow: scroll;
		max-width: 100%;
		@include media(sm) {
			padding: 2px;
		}
	}
	.tier-tile-backdrop {
		width: 100%;
		min-width: 200px;
		max-width: 320px;
		border: 1px solid $color-input-border !important;
		background-color: $color-primary-background !important;
		margin: 20px 10px;
		position: relative;
		@include media(sm) {
			width: auto;
		}
		&.b-carousel-item {
			&:focus-within {
				border: 1px solid;
			}
		}
		.selected-pill {
			display: none;
			width: 140px;
			height: 32px;
			border-radius: 20px;
			color: $color-primary !important;
			background: #C9B794 !important;
			border-color: #C9B794 !important;
			position: absolute;
			font: 16px/24px $font-main;
			letter-spacing: 0.5px;
			line-height: 24px;
			top: -16px;
			padding-top: 3px;
			@include icon(checkmark-plain, $icon-width: 12px, $icon-height: 12px) {
				display: inline-block;
				margin-right: 8px;
				margin-top: 5px;
				vertical-align: text-top;
			}
			&:before {
				background-color: $color-primary;
			}
		}
		&.active {
			border: 1px solid $color-secondary-accent !important;
			.selected-pill {
				display: block;
				margin: auto;
			}
		}
	}

	.tier-descMemship {
		border-top: 1px solid $color-shade_4;
		padding: 16px 0;
		margin: 5px;
		text-align: left;
		font: 400 12px/18px $font-main;
		letter-spacing: 0.4px;
		line-height: 18px;
	}

	.tierPriceMemship {
		font: 400 16px/40px $font-alt;
		letter-spacing: 0.5px;
		line-height: 24px;
		margin-top: 27px;
	}

 	.tierQtyMemship {
		font-family: $font-main;
		font-size: 16px;
		letter-spacing: 0.5px;
		line-height: 24px;
	 }

	.tierPercentMemship {
		font: 400 12px/18px $font-main;
		letter-spacing: 0.4px;
		line-height: 18px;
	}

	.b-carousel-track {
		gap: 0;
	}

}

.continueBtn {
	button {
		background-color: $color-secondary;
		color: $color-primary !important;
	}
}

.tier-details {
	.tier-details-title {
		font: 400 36px/48px $font-alt;
		letter-spacing: 0.8px;
		line-height: 48px;
		padding-top: 64px;
		padding-bottom: 16px;
	}
	.tier-description {
		font: 400 20px/32px $font-alt;
		letter-spacing: 0.5px;
	}
}

.b-add_to_wishlist {
	&::before {
		background-color: $color-secondary !important;
	}
}

.wcmembership-infotile {
	color: $color-secondary-accent;
  	font-family: $font-alt;
  	font-size: 26px;
  	font-weight: 500;
  	letter-spacing: 0.5px;
  	line-height: 40px;
	p {
	color: $color-primary;
    font-size: 16px;
    line-height: 24px;
	}
}

.wcproduct-tile {
	border: 1px solid $color-input-border;
}

.wcproduct-name {
	font-size: 20px;
	color: $color-secondary-accent;
	font-family: $font-main;
	letter-spacing: 0.7px;
	line-height: 32px;
	font-weight: 500;
	padding: 3px;
}

.selected {
	background-color: transparent !important;
	border: 3px solid $color-secondary;
}

.removebtn {
	background-color: $color-secondary-accent;
}

.wcproduct-actprice {
	text-decoration: line-through;
	font-size: 12px;
}

.editbtn button {
	color: $color-form-label;
}

// login form
.milkrun-login {
	.login-account-title,
	.create-account-title {
		font-weight: 500;
		color: $color-secondary-accent;
		letter-spacing: 0.7px;
	}

	.login-account-desc,
	.create-account-desc {
		letter-spacing: 0.5px;
	}

	.b-login_helpers-forgot_link {
		color: $color-secondary-accent;
	}
}

.your-shipment {
	.heading {
		border-bottom: 1px solid $color-primary;
	}

	.shipment-card {
		border: 1px solid $color-shade_4;

		.skip-modal {
			.skip {
				letter-spacing: 0.5px;
				text-transform: capitalize !important;
				color: $color-secondary-accent;
			}
		}
		
		.shipment-type {
			background: $color-secondary-accent;

			&.confirm {
				background-color: $color-account_nav-item__active-bg !important;
			}

			.title {
				font: 12px/18px $font-main !important;
				letter-spacing: 0.4px !important;
			}

			.customize {
				font: 12px/18px $font-main !important;
				letter-spacing: 0.4px !important;
			}
		}

		.title-block {
			border-bottom: 1px solid $color-shade_4;

			.title {
				font: 500 26px/40px $font-alt !important;
 				letter-spacing: 0.5px;
			}

			.skip {
				font: 16px/24px $font-main !important;
				letter-spacing: 0.5px !important;
				text-transform: capitalize;
			}
		}

		.shipment-info {
			@include media(md-up) {
				border-bottom: 1px solid $color-shade_4;
			}

			.shipment-info-col {
				.reschedule-modal-link {
					.b-login_helpers-forgot_link {
						font: 16px/24px $font-main !important;
						letter-spacing: 0.5px !important;
						color: $color-secondary-accent;
					}
				}
				.info-list {
					&:nth-child(n-1) {
						margin-bottom: 8px !important;
					}

					.info-title {
						letter-spacing: 0.5 !important;
						font: 500 16px/24px $font-main !important;
					}

					.info-amt {
						letter-spacing: 0.5;
						font-family: $font-main !important;
					}

					&.total {
						border-top: 1px solid $color-shade_4;
						font-weight: normal;
						font-family: $font-main;
					}
				}

				.discount {
					color: $color-primary;
				}

				.info-shipmentDate {
					font-size: 16px;
					letter-spacing: 0.6px;
					font-weight: 600;
					font-family: $font-main !important;
					color: $color-form-label;

					.info-amt.shipment-date-info {
						font-weight:600 !important;
					}
				}

				.shipping-info {
					.title {
						letter-spacing: 0.5px;
						margin-bottom: 8px !important;
						font: 500 12px/18px $font-alt !important;
						color: $color-form-label;
					}

					.description {
						font: 16px/24px $font-main !important;
						letter-spacing: 0.5px !important;
						color: $color-primary;

						&.info {
							color: $color-form-label !important;
							font: 12px/18px $font-main !important;
							letter-spacing: 0.4px;
						}
					}

					a {
						text-transform: capitalize !important;
						color: $color-primary;
						font: 16px/24px $font-main !important;
						letter-spacing: 0.5px !important;
					}
				}
			}
		}

		.shipping-products {
			.shipping-header {
				.title {
					letter-spacing: 0.7px;
					font: 500 20px/32px $font-alt !important;
					color: $color-secondary-accent;
				}

				.b-account_card-button.customizecard{
					font: 16px/24px $font-alt !important;
					letter-spacing: 0.5px !important;
                }
			}

			.shipping-items {
				.item {
					.item-container {
						.product-desc {
							.title {
								font: 600 16px/24px $font-alt !important;
								letter-spacing: 0.6px !important;
								margin-bottom: 16px;
								text-transform: capitalize !important;
								color: $color-secondary-accent;
							}

							.price {
								font: 16px/24px $font-main !important;
								letter-spacing: 0.5px !important;
								margin-bottom: 12px;
								.m-old {
									color: $color-form-label;
								}
							}

							.qty {
								font: 16px/24px $font-main ;
								letter-spacing: 0.5px;
							}
						}
					}
				}
			}
		}
	}
}
.wcproductprice {
	.b-product_price-value {
		&.m-old {
			color: $color-form-label !important;
		}
	}
}

.skip-shipment {
	border: 1px solid $color-input-border;
	.header {
		background-color: #ba8d36 !important;
		color: $color-primary !important;
	}
	.content {
		.title {
			font-size: 26px !important;
			line-height: 40px !important;
			letter-spacing: 0.5px !important;
			font-weight: 500 !important;
			margin-bottom: 24px !important;
		}
		.undo {
			text-transform: capitalize !important;
			font-size: 16px !important;
			line-height: 24px !important;
			letter-spacing: 0.5px !important;
		}
	}
}

.wineclub-modal {
	&.m-quick_view {
		.b-dialog-window {
			padding: 32px 20px;
		}
	}
}

.membership-title {
	border-bottom: 1px solid $color-shade_4 !important;
	font: 20px/32px $font-alt !important;
	font-weight: 500 !important;
	letter-spacing: 0.7px !important;
	color: $color-account_nav-item__active-text !important;
}

.wcmembership-info {
	border: 1px solid $color-input-border;
}

.wcmembership-title {
	color: $color-secondary-accent;
	font: 16px/24px $font-alt !important;
	font-weight: 600 !important;
	letter-spacing: 0.6px !important;
}

.addwcmembership-cta {
	border: 1px solid $color-secondary !important;
}

.addwcmembership-cta:hover {
	background: $color-secondary !important;
	color: $color-primary !important;
}

.wineclub-title {
	color: $color-primary;
	font: 36px/48px $font-alt !important;
	letter-spacing: 0.8px !important;
	line-height: 48px !important;
}

.discount {
	.info-title, .info-amt {
		color: $color-shade_10;
	}
}

.b-product_badge {

	&.m-promo {
		background: #F2F0E7;
		color: $color-primary;
	}
	&-image {
		background: none !important;
		border: none;
	}
	&.m-rating_points {
		background-image: url("./svg-icons/badge-designlogo.svg");
	}

	&-abbr, &-text {
		font-size: 12px !important;
		letter-spacing: 0.4px;
		line-height: 18px;
		margin-top: 5px;
		color: $color-secondary-accent !important;
	}

	&-points {
		font-size: 20px !important;
		font-weight: 500;
		letter-spacing: 0.7px;
		margin-top: 5px;
		color: $color-secondary-accent;
	}
}

.tasting-reservation {
    padding : 0;
	.tasting-reservation-col-even h2,
	.tasting-reservation-col-odd h2 {
    	color: $color-secondary-accent;
	}
}

.louis-desc {
	font-family: $font-main;
	font-size: 24px;
	letter-spacing: 0.23px;
	line-height: 39px;
}

@media (max-width: 767px) {
	.louis-desc {
		padding: 0 20px;
	}
}

.b-categories_navigation-item_1:after{
	border-bottom: 4px solid $color-secondary;
}

.b-categories_navigation-link_1{
	color: $color-secondary-accent;
}

// #Brand logo responsive #
.b-logo-wrapper {
	@include media(sm){
    	padding-bottom: 45px;
	}
}

.b-logo-image {
	&.logo {
		&-desktop {
			@include media(sm){
				display: none;
			}
		}
		&-mobile {
			display: none;
			@include media(sm){
				display: block;
			}
		}
	}
}

//footer
.b-footer{
	border-top: 1px solid $color-shade_8;
	.b-footer-bottom_info{
		border-top:0;
	}
	.b-footer_navigation{
		&-title{
			letter-spacing: 0.5px;
			font-family: $font-alt;
			font-weight: 500;
		}
		&-link{
			color:var(--color-link, $color-secondary-accent) !important;
			font: 16px/24px $font-alt;
			letter-spacing: 0.5px;
		}
	}

	.b-footer_social{
		&-title{
			letter-spacing: 0.5px;
			font-family: $font-alt;
			font-weight: 500;
		}
	}

	.b-footer-newsletter_title{
		@include t-heading_6;
		font: 400 16px/24px $font-alt !important;
		letter-spacing: 0.5px !important;
	}

	.b-footer-copyright{
		opacity: initial !important;
		font-size: 12px !important;
	}

	.b-footer_legal-link{
		color: var(--color-link, $color-secondary-accent);
		font: 400 12px/18px $font-alt;
		letter-spacing: 0.5px;
	}
}


// complete membership
.welcomeMsg {
	font: 36px/48px $font-alt;
	letter-spacing: 0.8px;
    line-height: 48px;
}

.redirectshipping {
	color: $color-primary;
	background-color: $color-secondary;
}

.b-carousel-pagination_control {
	border: 1px solid  $color-secondary;
}

.b-header {
	font-size: 16px;
	.b-header_login-title_link {
		&:before {
			min-width: 16px;
		}
		@include media(lg-up){
			min-width: 138px;
			padding: 12px;
		}
	}
	&.m-stuck {
		.b-categories_navigation {
			&-list_1 {
				@include media(lg-up) {
					padding: rh(8 0 0);
				}
			}
		}
		.b-header_login-title_link {
			min-width: 0px;
		}
		.b-header-utility_item {
				&.m-favorites {
					font-size: 0;
					min-width: 0;
			}
		}
	}
	&-utility_item {
		&.m-favorites:after,&.m-favorites_active:after{
			display: none;
		}
		&.m-favorites {
			@include icon(heart, $icon-width: 17px, $icon-height: 16px, $position: before);
			padding: 15px;
			&:before{
                margin-right: 10px;
				min-width: 16px;
            }
			@include media(lg-up){
				min-width: 138px;
			}
		}

		&.m-favorites_active {
			@include icon(wishlist-active, $icon-width: 17px, $icon-height: 16px, $position: before) {
				background: $color-accent;
			}
		}
	}
}

.b-ship_to-state {
    border-bottom: 1px solid;
	color: $color-secondary-accent;
}

.b-ship_to-link:after {
	height: 8px;
	width: 8px;
}

// wineclub registration form
.mr-register-form {
	.b-account_benefits {
		.b-account_benefits-title {
			font: 26px/40px $font-alt;
			font-weight: 500;
			letter-spacing: 0.5px;
			margin-bottom: 16px;
			color: $color-secondary-accent;
		}

		.b-account_benefits-item {
			color: $color-primary;
		}
	}

	.b-code_field-caption {
		.b-code_field-link {
			text-transform: capitalize;
			letter-spacing: 0.5px;
			line-height: 24px;
		}
	}
}

svg{
	color: $color-svg-icon;
}

.b-product_tile {
	&.m-grid{
		.b-product_quantity {
			border: $configuration-button_main-border_width solid $color-input-border !important;
		}
	}
}

.b-product_tile {
	&.m-grid {
	.b-product_quantity {
	&.m-disabled {
		border-color: $color-shade_4 !important;
				}
			}
		}
	}
.t-paragraph_1, .t-paragraph_2, .t-paragraph_3, .t-paragraph_4{
	letter-spacing: 0.5px;
}

.b-message{
	letter-spacing: 0.5px;
	&-link{
		letter-spacing: 0.5px;
	}
}

.f-field-caption, .f-field-error {
	letter-spacing: 0.4px;
	line-height: 18px;
	font-weight: 300;
}

.f-input_checkbox-field, .f-input_radio-field {
	background-color: $color-secondary;
}

.b-account_card-footer {
	.b-account_card-button {
		border-color: $color-secondary;
	}
}


.b-product_quantity {
	.b-product_quantity-label {
		&.m-minus:before {
    	 background: $color-shade_4;
		}
	}
}

.b-carousel-control {
 	border: 0;
}

.b-summary_table-address {
	color: $color-primary !important;
}
