// # Applied colors palette #
//============================================
// ## Main colors ##
$color-primary: #202025;
$color-primary-background: #fff;
$color-select-option: #202025;
$color-error: #CA1E36;
$color-error-background: #FAEDED;
$color-success: #37a10a;
$color-promo-background: #F6F6F5;
$color-input-border:  #A7A8A8;
$color-accent: #BA8D36;
$color-label-text: #202025;
$color-header: #BA8D36;
$color-secondary: #BA8D36;
$color-secondary-accent: #584C44;
$color-track-notification: rgba(196,194,194,0.1);
// ## Shades ##
$color-shade_1: #fafafa;
$color-shade_2: #efefef;
$color-shade_3: #A7A8A8;
$color-shade_4: #D2D2CE;
$color-shade_5: #D2D2CE;
$color-shade_6: #252525;
$color-shade_8: #C4C2C2;
$color-shade_9: #3F4142;
$color-shade_10:#1A862C;
// ## GH/GF colors ##
$color-header_text: $color-header;
$color-header_alt: $color-header;
$color-footer_text: $color-primary;
$color-footer_alt: $color-primary;

// ## SVG Icon color
$color-svg-icon: $color-secondary;

// ## Buttons ##
// ### Button Main ###
$color-button_main-background: $color-secondary;
$color-button_main-border: $color-secondary;
$color-button_main-text: $color-primary;
$color-button_main__hover-background: $color-primary-background;
$color-button_main__hover-border: $color-secondary;
$color-button_main__hover-text:  $color-primary;
$color-button_main__disabled-background: $color-shade_4;
$color-button_main__disabled-text: $color-primary-background;
$color-button_main__disabled-border: $color-shade_4;
$color-button_main__inversed-background: $color-primary-background;
$color-button_main__inversed-border: $color-secondary;
$color-button_main__inversed-text:$color-primary;
$color-button_main__inversed__hover-background: $color-secondary;
$color-button_main__inversed__hover-border: $color-secondary;
$color-button_main__inversed__hover-text: $color-primary;
$color-button_main__inversed__disabled-background: $color-primary-background;
$color-button_main__inversed__disabled-text: $color-shade_4;
$color-button_main__inversed__disabled-border: $color-shade_4;

// ### Alt ###
$color-button_alt-background: $color-primary-background;
$color-button_alt-border: $color-secondary;
$color-button_alt-text: $color-primary;
$color-button_alt__hover-background: $color-secondary;
$color-button_alt__hover-border: $color-secondary;
$color-button_alt__hover-text: $color-primary;	
$color-button_alt__disabled-background: $color-primary-background;
$color-button_alt__disabled-text: $color-shade_4;
$color-button_alt__disabled-border: $color-shade_4;
$color-button_alt__inversed-background:  $color-secondary;
$color-button_alt__inversed-border: $color-secondary;
$color-button_alt__inversed-text: $color-primary-background;
$color-button_alt__inversed__hover-background: $color-primary-background;
$color-button_alt__inversed__hover-border: $color-secondary;
$color-button_alt__inversed__hover-text: $color-secondary;
// ## Badges ##
$color-badge-1: #d8d8d8;
$color-badge-2: #bfb9a1;
//============================================

// Semantic colors mapping to components
// # COMMON #
$color-text__dark: $color-primary;
$color-text__light: $color-primary-background;
$color-overlay-background: $color-shade_5;

// # COMPONENTS #
$color-product_image-background:$color-primary-background ;

// # PDP #
$color-product_gallery-thumb-border: rgba($color-shade_5, 0.35);
$color-product_gallery-thumb__selected-border: #BA8D36;

// ## PRODUCT AVAILABILITY  ##
$color-product_availability-in_stock: $color-success;
$color-product_availability-low_stock: $color-success;
$color-product_availability-out_of_stock: $color-error;

// # PLP #
$color-filters-label: $color-input-border;

// ## PROGRESS BAR ##
$color-progress-bg: $color-primary-background;
$color-progress-value-bg: #BA8D36;

// ## MESSAGE ##
$color-message-text__inversed: $color-primary;

// ## Product tile ##
$color-product_tile-promo-background: $color-promo-background;
$color-product_tile-promo: $color-primary;

// ## Header ##
$color-header-background: $color-primary-background;
$color-header__transparent-text: $color-header_text;
$color-header__transparent__inverted-text: $color-header_alt;
$color-header__transparent-background-border: rgba($color-shade_4, 0.15);
$color-header__transparent-header_promo-background: $color-promo-background;
$color-navigation_flyout-background: $color-primary-background;
$color-hamburger_menu-background: $color-primary-background;
$color-hamburger_back_link-background: $color-primary-background;
$color-hamburger_account-background: $color-primary-background;
$color-cookies-background: $color-shade_5;//??
$color-header_promo__transparent_header-background: $color-badge-2;//+
$color-main_nav-divider: $color-shade_8;
$color-mobile_navigation-categories_divider: $color-secondary-accent;//+
$color-mobile_navigation-section_divider: $color-secondary-accent;
$color-shipmentdetails-border: #c6c6c6;

// ## Footer ##
$color-footer-background: $color-primary-background;
$color-footer-email_subscription-background: rgba($color-shade_6, 0.78);

// ## LINKS ##
$color-link: #584C44;
$color-link__visited: #584C44;
$color-link__disabled: $color-shade_4;

// ## NOTIFICATION ##
$color-notification-text: $color-primary;
$color-notification-background: $color-shade_4;

// # ERROR PAGES #
$color-error_410-text: $color-text__light;

// # PAGE DESIGNER #
$color-banner_2_region: $color-primary;

// # PDP #
$color-product_gallery-thumbs_control-background: rgba($color-shade_6, 0.9);


// ## BREADCRUMBS ##
$color-breadcrumb-current: #6A6A67;
$color-breadcrumb-separator_bg: $color-secondary;
$color-breadcrumb-link_color: $color-secondary-accent;

// ### SHIPPING STATES PANEL ###
$color-state__hover-background: #F6F6F5;

// # MY ACCOUNT #
$color-account_info_tile-bg: #F6F6F5;

// ## ACCOUNT NAVIGATION ##
$color-account_nav-item__active-text: $color-secondary-accent;
$color-account_nav-item__active-bg: #F6F6F5;

// # AGE GATE #
$color-age_gate-text: $color-primary;
$color-age_gate-background: $color-primary-background;

// # ERROR PAGES #
$color-error_410-text: $color-text__dark;
$color-error_404-text: $color-text__dark;
$color-error_page-background: $color-primary-background ;
$color-error_page-text: $color-text__dark;

// ## CAROUSEL ##
$color-carousel_control:$color-secondary;
$color-carousel_pagination_control: $color-primary-background;
$color-carousel_pagination_control__active: $color-secondary;

// ## CHECKOUT ##
$color-checkout_confirmation-account-copy: $color-primary;

// # STORE LOCATOR #
$color-store_locator-border: #C4C2C2;
$color-store_locator-background: $color-primary-background;
$color-store_locator-close_background: #F6F6F5;
$color-store_locator-close_text: $color-primary;
$color-store_locator-nav_background: $color-primary-background;
$color-store_locator-nav_text: $color-primary ;
$color-store-border: #C4C2C2;
$color-store-hover: #C4C2C2;

// ## FORMS ##
$color-form-input: $color-primary;
$color-form-input-background: $color-primary-background;
$color-form-input-border: $color-input-border;
$color-form-label: #6A6A67;
$color-form-caption: #6A6A67;

// ## Product tile ##
$color-product_tile-promo-background: $color-promo-background;
$color-product_tile-promo: $color-primary;
$color-quantity: #A7A8A8;

// # PLP #
$color-filters-label: $color-input-border;
$color-sorting-label: #6A6A67;

// ## PRODUCT TABLE ###
$color-product_table-head_text: $color-primary !default;//+
$color-product_table-sku: $color-primary !default;//+

// ## TEXT ##
$color-text-dimmed: $color-primary !default;//+
$color-form-label: $color-primary !default;//+
$color-form-input: $color-primary !default;
$color-form-caption: $color-primary !default;//+
